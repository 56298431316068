import React, { PureComponent, type ReactPortal } from 'react';
import ReactDOM from 'react-dom';
import uuid from 'uuid';

const REPLACED_MARKER = 'data-replaced';
const CLONE_MARKER = 'data-clone';

export type LegacyDOMBridgeProps = {
	selectors: string[];

	attempts?: number;
	retryDelay?: number;
	rootNode?: HTMLElement;
	portalNode: JSX.Element;
	replacePlaceholderWith?: {
		elementName: string;
		attributes?: Record<string, string>;
	};
	deleteExistingContent?: boolean;
};

export class LegacyDOMBridge extends PureComponent<LegacyDOMBridgeProps> {
	static defaultProps = {
		attempts: 1,
		retryDelay: 50,
		deleteExistingContent: true,
	};

	state = {
		attemptsLeft: this.props.attempts ?? 0,
	};

	workerInProgress = false;
	portals: ReactPortal[] = [];

	getLegacyElements(): Element[] {
		const { rootNode = window.document, selectors = [] } = this.props;
		if (!rootNode) {
			return [];
		}

		return Array.from(rootNode.querySelectorAll(selectors.join(',')));
	}

	getDomElementAttrsMap(domElement: Element): Record<string, string> {
		if (!domElement.hasAttributes()) {
			return {};
		}

		return Object.values(domElement.attributes).reduce<Record<string, string>>((acc, attr) => {
			acc[attr.name] = attr.value;
			return acc;
		}, {});
	}

	replaceDOMElementsWithPortal() {
		const { deleteExistingContent, replacePlaceholderWith, portalNode } = this.props;

		this.workerInProgress = true;

		this.getLegacyElements().forEach((node) => {
			const currentNodeAttrs = this.getDomElementAttrsMap(node);
			if (currentNodeAttrs[REPLACED_MARKER] || currentNodeAttrs[CLONE_MARKER]) {
				return; // do not clone clones or already cloned nodes
			}
			// @ts-expect-error FIXME: `true` here probably needs to be replaced with `"true"`
			node.setAttribute(REPLACED_MARKER, true);
			// @ts-expect-error FIXME: `true` here probably needs to be replaced with `"true"`
			currentNodeAttrs[CLONE_MARKER] = true;
			currentNodeAttrs['key'] = currentNodeAttrs.id || uuid();

			let targetNode = node;
			if (replacePlaceholderWith) {
				targetNode = window.document.createElement(replacePlaceholderWith.elementName);
				const attributes = replacePlaceholderWith.attributes || {};
				for (const attr in attributes) {
					targetNode.setAttribute(attr, attributes[attr]);
				}
				node.parentNode!.replaceChild(targetNode, node);
			}

			if (deleteExistingContent) {
				while (targetNode.firstChild) {
					targetNode.removeChild(targetNode.firstChild);
				}
			}
			if (portalNode) {
				const reactEl = React.cloneElement(portalNode, currentNodeAttrs);
				this.portals.push(ReactDOM.createPortal(reactEl, targetNode));
			}
		});

		const attemptsLeft = this.state.attemptsLeft - 1;
		this.setState({ attemptsLeft });

		if (attemptsLeft > 0) {
			window.setTimeout(() => {
				this.replaceDOMElementsWithPortal();
			}, this.props.retryDelay);
		} else {
			this.workerInProgress = false;
		}
	}

	render() {
		if (this.state.attemptsLeft > 0 && !this.workerInProgress) {
			setTimeout(() => {
				this.replaceDOMElementsWithPortal();
			}, 0);
		}
		return this.portals.map((item) => item);
	}
}
