import React, { memo } from 'react';
import type { VFC } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@compiled/react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { LegacyDOMBridge } from '@confluence/legacy-dom-bridge';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import { BannerStateContainer } from './BannerStateContainer';

type BannerSpacerDivProps = {
	height: number;
};

// only needs to be exported for testing, so do not include in the index.js file
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BannerSpacerDiv = styled.div<BannerSpacerDivProps>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: (props) => `${props.height}px`,
});

const BANNER_SPACER_CONTAINER_SELECTORS = [`.banner-spacer-container`];

const renderSpacer = () => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	return (
		<Subscribe to={[BannerStateContainer]}>
			{(bannerState: BannerStateContainer) => {
				const bannerHeight = bannerState.getTotalHeight(false);
				const bannerList = bannerState.getBannerList();
				return bannerHeight > 0 ? (
					<BannerSpacerDiv
						height={bannerHeight}
						data-testid="banner-spacer"
						data-vc={`banner-spacer-${bannerList}`}
						{...ssrPlaceholderIdProp}
					/>
				) : null;
			}}
		</Subscribe>
	);
};

const renderPortalSpacer = () => (
	<LegacyDOMBridge
		selectors={BANNER_SPACER_CONTAINER_SELECTORS}
		attempts={1}
		retryDelay={500}
		portalNode={renderSpacer()}
	/>
);

type BannerSpacerProps = {
	usePortalSpacer?: boolean;
};

export const BannerSpacer: VFC<BannerSpacerProps> = memo(({ usePortalSpacer = false }) =>
	usePortalSpacer ? renderPortalSpacer() : renderSpacer(),
);

BannerSpacer.propTypes = {
	usePortalSpacer: PropTypes.bool,
};
